<template>

    <section id="trabalhos" ref="trabalhos">
        <ItemJob :job="jobToShow" ref="itemJob"/>

        <div class="wrap">
            <div class="container-fluid">
                <b-row>
                    <b-col cols="12">
                        <div class="caption text-center">
                            <h3><strong class="tit-mini-dest tit-mini-dest--dark">Alguns dos trabalhos realizados em 2023 e 2024</strong></h3>
                            <!-- <h3>{{ texto_jobs.title }}</h3>
                            <p>{{ texto_jobs.description }}</p> -->
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <ul class="mt-4">
                            <template v-if="jobs.length  > 0">
                                <Job v-for="(job, index) in getJobs" :key="index" :job="job" @showDetail="showDetail"/>
                            </template>
                            <!-- <template v-else>
                                <Job v-for="(job,index) in 8" :key="index" :job="job" @showDetail="showDetail"></Job>
                            </template> -->
                        </ul>
                    </b-col>
                </b-row>

                <b-row class="mt-4 mb-4" v-show="jobs.length > getJobs.length">
                    <b-col class="d-flex justify-content-center">
                        <b-button class="bt-enc" @click="maxJobs = maxJobs + 9">
                            Mais trabalhos...
                            <b-icon icon="plus"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="trabalho-numeros mt-5 mb-4" id="numbers-count">
                    <b-col class="d-flex justify-content-evenly">
                        <div class="item-contador-numero">
                            <span id="numeros" data-to="1000">0</span>
                            <span>+</span>
                            <p class="shadow-text">Projetos</p>
                        </div>
                        <div class="item-contador-numero">
                            <span id="numeros" data-to="100">0</span>
                            <span>+</span>
                            <p class="shadow-text">Clientes</p>
                        </div>
                        <div class="item-contador-numero"> 
                            <span id="numeros" data-to="350">0</span>
                            <span>+</span>
                            <p class="shadow-text">Bots</p>
                        </div>
                        
                    </b-col>
                </b-row>
            </div>

        </div>
    </section>

</template>

<script>

export default {
    components: {
        ItemJob: () => import('../components/ItemJob.vue'),
        Job: () => import("../components/Jobs.vue"),
    },
    props: {
        texto_jobs: {},
        jobs: []
    },
    data() {
        return {
            jobOpen: 0,
            numberHasActived: false,
            jobToShow: {},
            maxJobs : 9
        }
    },
    computed: {
        getJobs() {
            var jobs = this.jobs.map((value,index)=>{
                if (index < this.maxJobs) {
                    return value
                }
            })


            jobs = jobs.filter(function (el) {
                return el != null;
            });

            return jobs
        }  
    },
    methods: {
        showDetail(data) {
            this.$refs.itemJob.showJob();
            this.jobToShow = data
        },
        counterNumbers() {

            if (this.numberHasActived) return;

            this.numberHasActived = true;

            const counters = document.querySelectorAll('#numeros');
            const speed = 100;

            counters.forEach(counter => {
                const animate = () => {
                    const value = +counter.getAttribute('data-to');
                    const data = +counter.innerText;
                    const time = value / speed;

                    if (data < value) {
                        counter.innerText = Math.ceil(data + time);
                        setTimeout(animate, 1);
                    } else {
                        counter.innerText = value;
                    }
                }
                animate()
            });
            return true
        }
    },
    mounted() {
        const _this = this;
        addEventListener('scroll', function () {
            const numberAdd = document.querySelector("#numbers-count")
            if (numberAdd.getBoundingClientRect().top <= 500) {
                //console.log(numberAdd.getBoundingClientRect().top)
                setTimeout(() => {
                    _this.counterNumbers();
                }, 200)
            }

        })

    }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/global";

// layer de job selecionado
.layer-job-selected {
    overflow: auto;
    background-color: $white;
    padding: 40px;
    position: fixed;
    bottom: 0;
    height: 100vh;
    display: flex;
    transform: translateY(100vh);
    transition: all .5s $custom;
    z-index: 12;

    .row {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-start;
    }

    h2 {
        color: $cor1;
        font-size: 30px;
    }

    h3, p {
        color: $cor2;
    }

    h3 {
        font-size: 26px;
    }

    p {
        font-size: 21px;
        margin-bottom: 15px;
    }

    &.actived {
        transform: translateY(0);
    }

    
}

p.shadow-text{
        font-weight: bold;
        color: #000;
        // text-shadow: -1px 0px 10px rgba(150, 150, 150, 1);
    }

////fim layer

ul {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content:  center;
    li {
        /* position: relative;
        display: block; */
        overflow: hidden;
        width: 100%;
        max-width: 400px;
        picture {
            /* width: 300px;
            height: 300px;
            position: relative;
            display: block;
            overflow: hidden;
            cursor: pointer; */

            img {
                /* position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0; */
                transition: all .7s ease;
                filter: sepia(0);
            }
        }

        .caption {
            background-color: rgba($cor2, .7);
            z-index: 1;
            position: absolute;
            bottom: 20px;
            left: 0px;
            right: 0px;
            padding: 5px 15px;
            transition: all .5s ease;
            transform: translateY(150px);
            opacity: 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            

            p {
                color: $cor1;
                font-size: 18px;
                width: 260px;
                padding-right: 10px;
            }

            .b-icon {
                color: $cor1;
                fill: $cor1;

                svg {
                }
            }
        }

        &:hover {
            img {
                transform: scale(1.1);
                filter: sepia(0%);
            }

            .caption {
                transform: translateY(0px);
                opacity: 1;
            }
        }
    }
    @media (max-width: $screenSD){
        justify-content: center;
    }
}

//Números Trabalho
.trabalho-numeros {
    span {
        font-size: 2rem;
        font-weight: 900;
    }

    p {
        color: $cor1;

        // &::before {
        //     content: "";
        //     width: 7px;
        //     height: 7px;
        //     border-radius: 50%;
        //     position: absolute;
        //     margin-top: 12px;
        //     margin-left: -15px;
        //     background: $cor1;

        // }
    }

    @media (max-width: $screenSD){
        .col{
            display: flex;
            flex-wrap: wrap;
            .item-contador-numero{
                margin: 0 30px 20px;
            }
        }
    }
}

</style>
